import { defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from '@vue/composition-api';
import type { Data, Ref } from '@vue/composition-api';

import { Btn, Inp } from '@/components/UI';

import pub from '@/public/pub';
import { req } from '@/api';
import store from '@/store';

import Empty from '@/components/UI/Empty';

import { generateOnlyid, encode, decode, showSecretNumber } from '@/public/methods';

//const DraftEditor = () =>
//    import('@/components/DraftEditor/index.vue').catch(() => {
//        //
//    });
//import RichTextEditor from '@/components/RichTextEditor';

const isLoading = ref(true);
const RichTextEditor = () =>
	import(/*webpackChunkName:'large-chunk-rich_text_editor'*/ '@/components/RichTextEditor')
		.then(component => {
			isLoading.value = false;
			return component;
		})
		.catch((error: any) => {
			alert('无法加载富文本编辑器组件(依赖于React ReactDOM Draft.js)，请前端程序员检查，或升级您的浏览器');
			isLoading.value = false;
			console.log('无法加载富文本编辑器组件', error);
		});

const editorStorageName = 'react-draft-wysiwyg-rich-text-editor-content-raw';
const editorStorageNameForHtml = 'react-draft-wysiwyg-rich-text-editor-content-html';
const editorStorageNameForMarkdown = 'react-draft-wysiwyg-rich-text-editor-content-markdown';
const clearEditorName = 'react-draft-wysiwyg-rich-text-editor-clear';
const userInfoStorageName = 'react-draft-wysiwyg-rich-text-editor-userinfo';

export default defineComponent({
	components: {
		RichTextEditor: RichTextEditor as any,
		Btn,
		Inp,
		Empty,
	},
	props: {
		tribe: {
			type: String,
			default: 'article',
			required: false,
		},
		onlyid: {
			type: String,
			default: '', //this.$route.params.onlyid || '',
			required: false,
		},
	},
	setup(props: any, { root }: any) {
		const { $message, $modal, $route } = root;
		const t = root.$t.bind(root);
		//编辑器加载的状态
		watch(
			() => isLoading.value,
			value => {
				if (value) {
					//console.log('加载中');
				} else {
					//console.log('加载完成');
				}
			},
			{ immediate: true }
		);
		const componentKey: Ref<string> = ref(generateOnlyid());
		const formKey: Ref<string> = ref(generateOnlyid());
		const isCompleted: Ref<boolean> = ref(false);
		const isForbid: Ref<boolean> = ref(false); //如果后台获取到了不允许评论，或是此文章不允许评论
		const forbidMsg: Ref<string> = ref('');
		//表单的数据
		const formDOM: Ref<any> = ref(null); //表单的DOM元素
		let initFormData = {
			content: '',
			contentHtml: '',
			contentMarkdown: '',
			avatarUrl: '/avatar.png',
			userQQ: '',
			userNickname: '',
			userMail: '',
			userUrl: '',
			isAnonymity: false,
			isSecret: false,
		};
		const isSavedUserInfo: Ref<boolean> = ref(false);
		//读取用户信息,非异步
		(() => {
			try {
				const userData = JSON.parse(decode(localStorage.getItem(userInfoStorageName) || '') || '{}');
				if (userData.avatarUrl && userData.userQQ && userData.userNickname && userData.userMail) {
					const { avatarUrl, userQQ, userNickname, userMail } = userData;
					initFormData = { ...initFormData, avatarUrl, userQQ, userNickname, userMail, userUrl: userData.userUrl || '' };
					isSavedUserInfo.value = true;
				}
			} catch (e) {
				//
			}
		})();
		const formData: Data = reactive({
			...initFormData,
		});
		const changeUserFormVal = {
			qq: (newValue: string) => (formData.userQQ = newValue),
			nickname: (newValue: string) => (formData.userNickname = newValue),
			mail: (newValue: string) => (formData.userMail = newValue),
			url: (newValue: string) => (formData.userUrl = newValue),
		};
		//提交、清空等操作
		const doClearComment = (isShowMessage = false) => {
			//清空富文本编辑器的内容
			const clearFn = pub.get(clearEditorName);
			typeof clearFn === 'function' && clearFn(isShowMessage);
			//清空本组件内输入的内容
		};
		const clearComment = () => {
			const c = confirm('此操作不可逆，即将清空您输入的所有内容，是否继续？');
			if (c) {
				doClearComment(true);
			}
		};
		const getOFormData = () => (formDOM.value ? new FormData(formDOM.value) : undefined);

		/*
		const getDataFromForm = (key: string) => {
			if (oFormData && typeof oFormData === 'object' && typeof oFormData.get === 'function') {
				return oFormData.get(key);
			} else {
				return formDOM.value?.querySelector(`[name=${key}]`)?.innerText || '';
			}
		};
		*/

		const sendComment = () => {
			const oFormData = getOFormData();
			/*
            console.log(
                '表单内容',
                formDOM.value,
                new FormData(formDOM.value)?.get('userQQ')
            ); //, new FormData(formDOM.value)
            */
			//alert(formDOM.value?.querySelector('[name=contentMarkdown]')?.innerText);
			//formData.contentHtml = '' + (oFormData?.get('contentHtml') || formDOM.value?.querySelector('[name=contentHtml]')?.innerHTML || '');
			//formData.contentMarkdown = '' + (oFormData?.get('contentMarkdown') || formDOM.value?.querySelector('[name=contentMarkdown]')?.innerHTML || '');
			//const formData: any = {};
			const neGetData = (key: string) => {
				if (oFormData && typeof oFormData === 'object' && typeof oFormData.get === 'function') {
					return oFormData?.get(key);
				} else {
					return '';
				}
			};
			formData.avatarUrl = formDOM.value?.querySelector('[name=avatarUrl]')?.getAttribute('src') || neGetData('avatarUrl'); // || oFormData?.get('avatarUrl') || '';
			formData.userQQ ||= formDOM.value?.querySelector('[name=userQQ]')?.getAttribute('data-current-value') || neGetData('userQQ'); // || oFormData?.get('userQQ') || '';
			formData.userNickname ||= formDOM.value?.querySelector('[name=userNickname]')?.getAttribute('data-current-value') || neGetData('userNickname'); // || oFormData?.get('userNickname') || '';
			formData.userMail ||= formDOM.value?.querySelector('[name=userMail]')?.getAttribute('data-current-value') || neGetData('userMail'); // || oFormData?.get('userMail') || '';
			formData.userUrl ||= formDOM.value?.querySelector('[name=userUrl]')?.getAttribute('data-current-value') || neGetData('userUrl'); // || oFormData?.get('userUrl') || '';
			//formData.isAnonymity ||= !!oFormData?.get('isAnonymity');
			//formData.isSecret ||= !!oFormData?.get('isSecret');
			//console.log('数据', formData, 'pub', pub.get(editorStorageName));
			formData.content = JSON.stringify(pub.get(editorStorageName) || {});
			const contentHtml = pub.get(editorStorageNameForHtml);
			const contentMarkdown = pub.get(editorStorageNameForMarkdown);
			//console.log('表单的内容', formData);
			//console.log($route);
			//console.log('md:', formData.contentMarkdown);
			//console.log('当前的contentMarkdown', formData.contentMarkdown);
			//console.log('当前的formData', formData);

			new Promise((resolve, reject) => {
				//
				req('SEND_COMMENT', {
					data: JSON.stringify({
						...formData,
						contentHtml,
						contentMarkdown,
						__tribe: props.tribe,
						__onlyid: props.onlyid || $route?.params?.onlyid || '',
						__articleOnlyid: props.onlyid || $route?.params?.onlyid || '',
						/*
                            $route.name === 'BlogArticleDetailId'
                                ? $route?.params?.onlyid || ''
                                : '',
                                */
					}),
					tribe: 'article', //以后再细分，文章评论，说说评论,留言等等
				})
					.then(data => {
						//console.log('发表后获得的data', data);
						//$message.success('评论/留言已提交，待审核后公开展示');
						$message.success(t('partCommentPublish.completedTip') || '评论/留言已提交，待审核后公开展示');
						resolve(data);
					})
					.catch(error => {
						reject(error);
					});
			})
				.then(data => {
					//提交成功之后
					//将formData里的HTML和Markdown数据清空
					formData.contentHtml = '';
					formData.contentMarkdown = '';
					//将编辑器清空
					doClearComment();
					//将临时存储清空
					pub.set(editorStorageName, {});
					//将本地存储清空
					localStorage.setItem(editorStorageName, '{}');
					//向评论列表组件发送刷新指令
					store.commit('touchCommentList', true);
					//存储已输入的昵称等信息，以便下次再输入
					(() => {
						const { avatarUrl, userQQ, userMail, userNickname, userUrl } = formData;
						localStorage.setItem(userInfoStorageName, encode(JSON.stringify({ avatarUrl, userQQ, userMail, userNickname, userUrl })));
					})();
					//隐藏发表评论的组件，转换至成功的界面
					isCompleted.value = true;
					//刷新组件
					componentKey.value = generateOnlyid();
				})
				.catch(error => {
					//提交失败之后
					try {
						$message.error(error);
					} catch (e) {
						alert(error);
					}
					//向评论列表组件发送刷新指令
					//store.commit('touchCommentList', true);

					//$message?.error(error) //|| alert(error);
				});
		};
		/*
        onMounted(() => {
            formDOM.value
                ?.querySelector('data-blurthings')
                .addEventListener('blur', alert);
        });
        */
		const qqblur = () => {
			//console.log(formDOM.value?.querySelector('[name=userQQ]'));
			//console.log(getOFormData());
			const qq = formData.userQQ || /*getOFormData()?.get('userQQ') ||*/ formDOM.value?.querySelector('[name=userQQ]')?.getAttribute('value') || ''; //oFormData?.get('userQQ') || '';
			//console.log(qq);
			if (!qq) {
				return;
			}
			req('API_QQ_GETUSER', {
				qq,
			})
				.then((data: any) => {
					//console.log('获取QQ信息',data);
					const setOptions: any = {
						avatarUrl: data?.avatar || '',
						userQQ: data?.qq || '',
						userNickname: data?.nickname || '',
						userMail: data?.email || '',
					};
					const v = formDOM.value;
					for (const key in setOptions) {
						const DOM = (formDOM.value as any)?.querySelector('[name=' + key + ']');
						formData[key] = setOptions[key];
						if (DOM) {
							DOM.setAttribute('value', setOptions[key]);
							DOM.setAttribute('src', setOptions[key]);
						} else {
							alert(key);
						}
					}
					//console.log(formData);
				})
				.catch(error => {
					$message.error(error);
				});
		};
		//监听js报错
		const errorListener = () => {
			$modal('文本编辑器发生了严重的错误。小站脆弱，经不起跨站脚本攻击！请不要在留言/评论区随意粘贴外站内容！在清空浏览器缓存之前，您将无法再进行留言/评论的操作，并且会频繁收到此类报错信息！');
			pub.set(editorStorageName, {});
			//将本地存储清空
			localStorage.setItem(editorStorageName, '{}');
		};
		onMounted(() => {
			window.addEventListener('error', errorListener);
		});
		onBeforeUnmount(() => {
			window.removeEventListener('error', errorListener);
		});
		return {
			isLoading,
			formDOM,
			formData,
			clearComment,
			sendComment,
			qqblur,
			isSavedUserInfo,
			changeUserFormVal,
			formKey,
			componentKey,
			showSecretNumber,
			isCompleted,
			isForbid,
			forbidMsg,
			t,
		};
	},
});
