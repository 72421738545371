import { defineComponent, ref, watch, onMounted, onBeforeUnmount, nextTick } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import FetchData from '@/components/FetchData';
import { Empty } from '@/components/UI';
import store from '@/store';
import { parseTime, isUrl, generateOnlyid } from '@/public/methods';
import hljs from 'highlight.js';
import 'highlight.js/styles/atelier-heath-light.css';

let timer: number | undefined = undefined;
let beforeScrollTop: number = document.documentElement.scrollTop || document.body.scrollTop;
const limitImgUrl = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

interface CommentDataReply {
	key: string | number;
	userID?: string;
	nickname: string;
	target?: {
		key: string | number;
		userID?: string;
		nickname?: string;
	};
	avatar: string;
	time: number;
	content: string;
	contentType?: string;
}
interface CommentData {
	key: string | number;
	userID?: string;
	nickname: string;
	url?: string;
	ua?: {
		browser: string;
	};
	ip?: string;
	avatar: string;
	time: number;
	content: string;
	contentType?: string;
	reply?: CommentDataReply[];
}
interface CommentDataT {
	page: number;
	totalPage: number;
	everyPageCount: number;
	totalResultCount: number;
	data: CommentData[];
}

export default defineComponent({
	components: { FetchData, Empty },
	directives: {
		spec(el) {
			el.querySelectorAll('a').forEach((a: HTMLElement) => {
				if (a && typeof a === 'object') {
					a.setAttribute('target', '_blank');
					a.addEventListener('click', function (event: MouseEvent) {
						const href = this.getAttribute('href');
						const encodeHref = encodeURIComponent('' + href);
						window.open('/redirect/extweb.jsp?u=' + encodeHref);
						event.preventDefault();
						return false;
					});
				}
			});
		},
		highlight(el) {
			el.querySelectorAll('pre code').forEach((block: Element) => {
				if (block && typeof block === 'object' && !/Trident/.test(navigator.userAgent)) {
					(hljs as any).highlightBlock(block as HTMLElement);
				}
			});
		},
	},
	props: {
		tribe: {
			type: String,
			default: 'article',
			required: false,
		},
		onlyid: {
			type: String,
			default: '', //this.$route.params.onlyid || '',
			required: false,
		},
		/*
		timeOrderDesc: {
			//是否时间反序
			type: Boolean,
			default: false,
			required: false,
		},
		*/
	},
	setup(props: any, { root }: any) {
		const { $route } = root;
		const t = root.$t.bind(root);
		const commentData: Ref<CommentDataT> = ref({
			page: 0,
			totalPage: 0,
			everyPageCount: 0,
			totalResultCount: 0,
			data: [
				{
					key: 'adwquiqwu',
					userID: 'admin',
					nickname: '网友一',
					url: '',
					avatar: store.state.limitImgUrl || limitImgUrl,
					time: 1475523581,
					content: '这是一条评论',
					contentType: 'markdown',
					reply: [
						{
							key: 'euy12',
							nickname: '网友二',
							target: {
								key: 'adwquiqwu',
								userID: 'admin',
							},
							avatar: store.state.limitImgUrl || limitImgUrl,
							time: 1510254111,
							content: '这是一条回复',
							contentType: '呵呵了',
						},
					],
				},
				{
					key: 'usa786756',
					userID: 's',
					nickname: '网友三',
					avatar: limitImgUrl,
					time: 16742158851,
					content: '这是另外一条评论',
				},
			],
		});
		const scrollTarget = () => {
			const targetEl = document.querySelector(`[data-scroll-target="${$route.query._target}"]`);
			if (targetEl) {
				//console.log('找到了元素');
				const y = (() => {
					let res = 0;
					let targetEl_ = targetEl;
					let i = 0;
					do {
						if (targetEl_ && (targetEl_ as HTMLElement).offsetTop) {
							res += (targetEl_ as HTMLElement).offsetTop;
							targetEl_ = (targetEl_ as HTMLElement).offsetParent!;
						}
						if (!targetEl_) {
							break;
						}
						i++;
						if (i > 100) {
							break;
						}
					} while (targetEl_ !== document.body && targetEl_ !== document.documentElement);
					res -= 60;
					return res;
				})();
				document.documentElement.scrollTop = y;
				try {
					//document.documentElement.scrollTo(0, y);
				} catch (e) {
					//
				}
			}
		};
		const touchData = (data: CommentDataT) => {
			//console.log(data);
			commentData.value = data;
			//根据评论区位置进行转换
			$route.query._target && scrollTarget();
		};
		//功能块，评论区延迟加载
		const articleCommentListBodyRef: Ref<HTMLElement | null> = ref(null);
		const onScrollChange = (event: any) => {
			const afterScrollTop: number = document.documentElement.scrollTop || document.body.scrollTop;
			const deltaScrollTop: number = afterScrollTop - beforeScrollTop;
			beforeScrollTop = afterScrollTop;
			if ((deltaScrollTop >= 0 && afterScrollTop !== 0 && articleCommentListBodyRef.value) || (event === true && afterScrollTop !== 0 && deltaScrollTop === 0)) {
				if (
					(articleCommentListBodyRef.value as any)?.getBoundingClientRect().top - (document.documentElement.clientHeight || document.body.clientHeight) < 100 ||
					!(articleCommentListBodyRef.value as any)?.getBoundingClientRect
				) {
					window.removeEventListener('scroll', onScrollChange);
					window.removeEventListener('wheel', onScrollChange);
					clearTimeout(timer);
					timer = setTimeout(() => {
						listKey.value = generateOnlyid();
					}, 30);
				}
			}
		};
		onMounted(() => {
			onScrollChange(true);
			window.addEventListener('scroll', onScrollChange);
			window.addEventListener('wheel', onScrollChange);
		});
		onBeforeUnmount(() => {
			window.removeEventListener('scroll', onScrollChange);
			window.removeEventListener('wheel', onScrollChange);
			clearTimeout(timer);
		});
		//唯一标识符，用于更改时刷新列表
		const listKey: Ref<string> = ref(generateOnlyid());
		//是否顯示
		const isShowList: Ref<boolean> = ref(true);
		//监听是否要刷新评论列表
		watch(
			() => store.state.isTouchCommentList,
			value => {
				if (value) {
					//alert('接收到了刷新指令');
					//刷新列表
					listKey.value = generateOnlyid();
					store.commit('touchCommentList', false);
					isShowList.value = false;
					nextTick(() => (isShowList.value = true));
				}
			},
			{
				//immediate: true,
			}
		);
		//监听页码的改变
		//const currentPage: Ref<number> = ref(Number($route.query.commentPage));
		//console.log($route.query.commentPage);
		watch(
			() => $route.query.commentPage,
			value => {
				//alert('监听到了');
				$route.query.commentPage = String(value);
				console.log($route.query);
				store.commit('touchCommentList', true);
			}
		);
		//打开链接
		const toUserUrl = (event: Event, url: any): void => {
			//console.log(event);
			if (isUrl(url)) {
				window.open(url);
			} else {
				//$message.warning('无效的链接' + url);
			}
		};

		return { commentData, touchData, t, parseTime, isUrl, generateOnlyid, toUserUrl, listKey, isShowList };
	},
});
